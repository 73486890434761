import React, { useState, useEffect } from 'react';
import '../App.css';

function App() {
  const [text, setText] = useState('');
  const [showComingSoon, setShowComingSoon] = useState(false);
  const fullText = 'do you like it?';

  useEffect(() => {
    let index = 0;
    setShowComingSoon(true);
    const typingEffect = setInterval(() => {
      setText(fullText.substring(0, index + 1));
      index++;
      if (index === fullText.length) {
        clearInterval(typingEffect);
      }
    }, 100);

    return () => clearInterval(typingEffect);
  }, [showComingSoon]);

  const handleComingSoonClick = () => {
    setShowComingSoon(false);
    setTimeout(() => {
      setShowComingSoon(true);
    }, 0);
  };

  return (
    <div className="App">
      <header className="App-header" style={{ backgroundColor: "#006EBB" }}>
        <h1 className="gluten" style={{ color: "#FFE15A" }}>DYLI</h1>
        <div style={{ height: "24px", marginLeft:"10px", marginTop: "-100px" }}>
          <p className="grandstander" style={{ color: "#FFFFFF" }}>
            {text}
          </p>
        </div>
      </header>
      {showComingSoon && (
        <div
          style={{
            position: 'fixed',
            right: '20px',
            backgroundColor: '#FFFFFF',
            cursor: 'pointer',
            color: '#006EBB',
            padding: '10px',
            borderRadius: '5px',
          }}
          className="bounce"
          onClick={handleComingSoonClick}
        >
          👍 coming soon
        </div>
      )}
    </div>
  );
}

export default App;
